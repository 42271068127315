import React, { useState } from "react";
import logo from "../assets/images/CamelCoding-logo.svg";
import web from "../assets/images/web.png";
import facebook from "../assets/images/facebook.png";
import linkedin from "../assets/images/linkedin.png";

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.outerWidth);

  const goToCamelCoding = () => {
    window.open("https://camelcoding.com/our_work/", "_blank");
  };

  const goToCalendly = () => {
    window.open(" https://calendly.com/camelcoding/30min", "_blank");
  };

  const goToWebpage = () => {
    window.open("https://camelcoding.com/", "_blank");
  };

  const goToFacebook = () => {
    window.open("https://www.facebook.com/CamelCoding", "_blank");
  };

  const goToLinkedin = () => {
    window.open("https://ro.linkedin.com/company/camelcoding", "_blank");
  };

  return (
    <>
      {windowWidth < 991 ? (
        <>
          <div className="container">
            <div className="logo">
              <img src={logo}></img>
            </div>
            <div className="container-inner">
              <div className="left-inner-container">
                <div className="coming-soon">COMING SOON</div>
                <div className="title">
                  <h1>Creating Innovative Solutions for the Web3 Ecosystem</h1>
                </div>
                <div className="texts">
                  <p>
                    Welcome to CamelCoding's Web3 portfolio, where we showcase
                    our expertise in blockchain development, smart contract
                    development, and decentralized application (dApp)
                    development.
                  </p>
                  <p>
                    With a background in computer science and a passion for
                    creating innovative solutions for the Web3 ecosystem, we
                    specialize in designing and implementing secure,
                    decentralized systems that are built to last.
                  </p>
                  <p>
                    We believe in the power of Web3 to transform industries and
                    drive social change, and we are committed to helping our
                    clients navigate this exciting and rapidly evolving
                    landscape.
                  </p>
                  <p>
                    Stay tuned for updates on our latest projects, and don't
                    hesitate to get in touch with us to discuss your next Web3
                    project.
                  </p>
                  <p className="stay-tuned">Launching soon - stay tuned!</p>
                  <hr className="hr" />
                </div>
              </div>
            </div>
          </div>
          <div className="links">
            <img
              src={web}
              alt="web"
              className="link"
              onClick={goToWebpage}
            ></img>

            <img
              src={facebook}
              alt="facebook"
              className="link"
              onClick={goToFacebook}
            ></img>

            <img
              src={linkedin}
              alt="linkedin"
              className="link"
              onClick={goToLinkedin}
            ></img>
          </div>
          <p className="footer">2023 CamelCoding | Coming soon</p>
          <div className="right-inner-container">
            <div className="box">
              <h2>If you would like to learn more:</h2>
              <button className="schedule" onClick={goToCalendly}>
                Schedule a meeting
              </button>
              <p className="or">or</p>

              <button className="check" onClick={goToCamelCoding}>
                Check our previous works
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="container">
          <div className="logo">
            <img src={logo}></img>
          </div>
          <div className="container-inner">
            <div className="left-inner-container">
              <div className="coming-soon">COMING SOON</div>
              <div className="title">
                <h1>Creating Innovative Solutions for the Web3 Ecosystem</h1>
              </div>
              <div className="texts">
                <p>
                  Welcome to CamelCoding's Web3 portfolio, where we showcase our
                  expertise in blockchain development, smart contract
                  development, and decentralized application (dApp) development.
                </p>
                <p>
                  With a background in computer science and a passion for
                  creating innovative solutions for the Web3 ecosystem, we
                  specialize in designing and implementing secure, decentralized
                  systems that are built to last.
                </p>
                <p>
                  We believe in the power of Web3 to transform industries and
                  drive social change, and we are committed to helping our
                  clients navigate this exciting and rapidly evolving landscape.
                </p>
                <p>
                  Stay tuned for updates on our latest projects, and don't
                  hesitate to get in touch with us to discuss your next Web3
                  project.
                </p>
                <p className="stay-tuned">Launching soon - stay tuned!</p>
              </div>
            </div>
            <div className="right-inner-container">
              <div className="box">
                <h2>If you would like to learn more:</h2>
                <button className="schedule" onClick={goToCalendly}>
                  Schedule a meeting
                </button>
                <p className="or">or</p>

                <button className="check" onClick={goToCamelCoding}>
                  Check our previous works
                </button>
              </div>
              <div className="links">
                <img
                  src={web}
                  alt="web"
                  className="link"
                  onClick={goToWebpage}
                ></img>

                <img
                  src={facebook}
                  alt="facebook"
                  className="link"
                  onClick={goToFacebook}
                ></img>

                <img
                  src={linkedin}
                  alt="linkedin"
                  className="link"
                  onClick={goToLinkedin}
                ></img>
              </div>
            </div>
          </div>
          <p className="footer">2023 CamelCoding | Coming soon</p>
        </div>
      )}
    </>
  );
};

export default Home;
